import { NftGalleryModel } from '@/models/nft-gallery-model'
import { apiService } from '@/services/api-services'
import { action, computed, IReactionDisposer, observable, reaction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { mapValues, groupBy, omit, map, isEmpty, uniqBy, orderBy } from 'lodash-es'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { PopoAvatarModel } from '@/models/popo-avatar-model'
import { walletStore } from './wallet-store'
import { DecoratedPopoModel } from '@/models/decorated-popo-model'
import { promiseHelper } from '@/helper/promise-helper'
import { getPopoNftOwned, getWearableNftOwned } from '@/handlers/utils'
import { localData } from './local-data'
import { ProfileHandler } from '@/services/handlers/profileHandler'
import { PopoNftModel } from '@/models/popo-nft-model'
import { WearableNftModel } from '@/models/wearable-nft-model'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { PoPoSellerHandler } from '@/handlers/popoSellerHandler'

export const DECORATE_TYPES = [
  { type: 'body', label: 'Body' },
  { type: 'top', label: 'Top' },
  { type: 'bottom', label: 'Bottoms' },
  { type: 'shoe', label: 'Shoes' },
  { type: 'hair_accessory', label: 'Hair Accessories' },
  { type: 'face_accessory', label: 'Face Accessories' },
  { type: 'prop_accessory', label: 'Prop Accessories' },
  { type: 'background_accessory', label: 'Background Accessories' },
]
export const WEARABLE_NFT_TYPES = ['top', 'bottom', 'hair_accessory', 'prop_accessory']

export class PopoStore {
  @observable galleries: NftGalleryModel[] = []
  @observable decorateTypes = DECORATE_TYPES
  @observable openPopoDecorateDialog = false
  @observable decoratedNfts: NftGalleryModel[] = []
  @observable isFetchingGalleries = false
  @observable generatePopoAvatarLoading = false
  @observable claimPopoLoading = false
  @observable closetAvatars: PopoAvatarModel[] = []
  @observable updateAvatarLoading = false
  @observable decoratedPoPos: DecoratedPopoModel[] = []
  @observable openConfirmMintingDialog = false
  @observable openClaimPopoDialog = false
  @observable openMintPopoSuccessDialog = false

  @observable selectedClosetAvatar?: PopoAvatarModel = undefined
  @observable claimedNftInfo = undefined as any
  @observable builtDecoratedPopos = undefined as any
  @observable activeDecoratedPopoNftInfo = undefined as any
  @observable activeDecoratedPopoNftId = undefined as any
  @observable setActiveDecoratedPoPoLoading = false
  @observable fetchDecoratedPoposLoading = false
  @observable popoNftOwned: PopoNftModel[] = undefined as any
  @observable wearableNftOwned: WearableNftModel[] = undefined as any

  // attachment
  @observable attachableNftCollection: WearableNftModel[] = undefined as any
  @observable handleActtachNftsLoading = false
  @observable fetchNftOwnedOnChainLoading = false
  @observable loadedDecoratedPopos = false
  @observable fetchAttachableNftCollectionLoading = false
  @observable popoAttachmentDialogLoading = false
  @observable openPopoAttachSuccessDialog = false
  @observable openPopoAttachmentDialog = false
  @observable selectedDecoratedPopoAttach = undefined as any
  @observable attachNfts: WearableNftModel[] = []

  // detach
  @observable selectedDecoratedPopoDetach = undefined as any
  @observable openPopoDetachAllDialog = false
  @observable popoDetachAllDialogLoading = false
  @observable detachAllLoading = false

  @observable openPopoDetachSingleDialog = false
  @observable popoDetachSingleDialogLoading = false
  @observable detachSingleLoading = false
  @observable openPopoDetachSuccessDialog = false
  @observable isOptInSellerApplication = false
  @observable havePendingNFTs = false
  @observable optInLoading = false
  @observable minting = false
  @observable pendingDecoratedPoPo = undefined as any
  @observable openRemindClaimPopoDialog = false

  @observable updatedPopoAvatar = undefined
  @observable openUpdateAvatarSuccessDialog = false

  @observable builtPendingDecoratedPoPoInfo = undefined as any

  private _disposers: IReactionDisposer[]
  private _loaded = false
  popoSellerHandler?: PoPoSellerHandler

  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.userProfile,
        async (userProfile) => {
          if (userProfile?._id) {
            await when(() => localData.getAccessToken())
            this.load()
            this.fetchClosetAvatar()
            this.fetchDecoratedPopos()
            this.fetchNftOwnedOnChain()
            if (userProfile?.active_decorated_popo && userProfile?.walletAddress) {
              this.activeDecoratedPopoNftId = userProfile?.active_decorated_popo
            }
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ]
  }

  @asyncAction *load() {
    if (this._loaded) return
    this._loaded = true
    yield Promise.all([this.fetchGalleries(), this.initBlockchain()])
  }

  @asyncAction *initBlockchain() {
    // try {
    //   this.popoSellerHandler = new PoPoSellerHandler()
    //   this.isOptInSellerApplication = yield this.popoSellerHandler?.isOptedInApplication(walletStore.account)
    //   const pendingNFTs = yield this.popoSellerHandler.getPendingNFTs(walletStore.account)
    //   // console.log('=========pendingNFTs', pendingNFTs)
    //   if (pendingNFTs.length) {
    //     this.havePendingNFTs = true
    //     const pendingDecoratedPoPo = yield apiService.decoratedPopos.getPendingDecoratedPoPo()
    //     if (pendingDecoratedPoPo) {
    //       this.pendingDecoratedPoPo = pendingDecoratedPoPo
    //       this.builtPendingDecoratedPoPoInfo = this.buildDecoratedPopoInfo(pendingDecoratedPoPo)
    //     }
    //     console.log('========pendingDecoratedPoPo', pendingDecoratedPoPo)
    //   }
    // } catch (e: any) {
    //   snackController.error(e.message || e.msg)
    // }
  }

  @asyncAction *fetchNftOwnedOnChain() {
    try {
      this.fetchNftOwnedOnChainLoading = true

      const walletAddress = walletStore.userProfile?.walletAddress
      if (!walletAddress) return
      const [popoNftOwned, wearableNftOwned] = yield Promise.all([
        getPopoNftOwned(walletAddress),
        getWearableNftOwned(walletAddress),
      ])
      this.popoNftOwned = popoNftOwned
      this.wearableNftOwned = wearableNftOwned?.map((item) => {
        return { ...item, image: item?.nft_gallery?.image, priority: item?.nft_gallery?.priority }
      })
    } catch (e) {
      console.log('error syncNftOwnedOnChain:', e)
    } finally {
      this.fetchNftOwnedOnChainLoading = false
    }
  }

  destroy() {
    this._disposers.forEach((d) => d())
  }

  // DETACHMENT

  @asyncAction *changeOpenPopoDetachAllDialog(value, decoratedPopoId = undefined) {
    if (this.popoDetachAllDialogLoading) return
    this.popoDetachAllDialogLoading = true
    if (value) {
      this.selectedDecoratedPopoDetach = undefined
      const selectedDecoratedPopoDetach = this.decoratedPoPos.find((item) => item.id == decoratedPopoId)
      if (!selectedDecoratedPopoDetach) return
      yield when(() => !this.fetchDecoratedPoposLoading)
      this.selectedDecoratedPopoDetach = this.buildDecoratedPopoInfo(selectedDecoratedPopoDetach)
    }
    this.openPopoDetachAllDialog = value

    this.popoDetachAllDialogLoading = false
  }

  @asyncAction *detachAllNft() {
    try {
      this.detachAllLoading = true

      const payload = {
        decoratedPoPoId: this.selectedDecoratedPopoDetach?.id,
        wearableNFTIds: map(this.detachNfts, 'nftId'),
      }
      yield apiService.decoratedPopos.detachNft(payload)
      this.fetchNftOwnedOnChain()
      this.fetchDecoratedPopos()
      this.changeOpenPopoDetachAllDialog(false)
      this.changeOpenPopoDetachSuccessDialog(true)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.detachAllLoading = false
    }
  }

  @asyncAction *changeOpenPopoDetachSingleDialog(value, decoratedPopoId = undefined, nftId = undefined) {
    if (this.popoDetachSingleDialogLoading) return
    this.popoDetachSingleDialogLoading = true
    if (value) {
      this.selectedDecoratedPopoDetach = undefined
      let selectedDecoratedPopoDetach = this.decoratedPoPos.find((item) => item.id == decoratedPopoId) as any
      if (!selectedDecoratedPopoDetach) return
      yield when(() => !this.fetchDecoratedPoposLoading)
      selectedDecoratedPopoDetach = this.buildDecoratedPopoInfo(selectedDecoratedPopoDetach)

      selectedDecoratedPopoDetach.wearableGalleries =
        selectedDecoratedPopoDetach?.wearableGalleries?.filter((item) => item.nftId === nftId) || []
      this.selectedDecoratedPopoDetach = selectedDecoratedPopoDetach
    }
    this.openPopoDetachSingleDialog = value

    this.popoDetachSingleDialogLoading = false
  }

  @asyncAction *detachSingleNft() {
    try {
      this.detachSingleLoading = true

      const payload = {
        decoratedPoPoId: this.selectedDecoratedPopoDetach?.id,
        wearableNFTIds: map(this.detachNfts, 'nftId'),
      }
      yield apiService.decoratedPopos.detachNft(payload)
      this.fetchNftOwnedOnChain()
      this.fetchDecoratedPopos()
      this.changeOpenPopoDetachSingleDialog(false)
      this.changeOpenPopoDetachSuccessDialog(true)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.detachSingleLoading = false
    }
  }

  // ==========> DETACHMENT END

  // ATTACHMENT
  @asyncAction *changeOpenPopoAttachmentDialog(value, decoratedPopoId = undefined) {
    if (this.popoAttachmentDialogLoading) return
    this.popoAttachmentDialogLoading = true
    if (value) {
      const selectedDecoratedPopoAttach = this.decoratedPoPos.find((item) => item.id == decoratedPopoId)
      if (!selectedDecoratedPopoAttach) return
      yield when(() => !this.fetchDecoratedPoposLoading && !this.fetchNftOwnedOnChainLoading)
      this.fetchAttachableNftCollection(selectedDecoratedPopoAttach)
      this.attachNfts = ([...this.attachableNftCollection] || []).filter((item) => item.forceAttach)
      const popoNftInfos = this.buildPopoNftInfo(selectedDecoratedPopoAttach)
      this.selectedDecoratedPopoAttach = { ...selectedDecoratedPopoAttach, popoNftInfos }
      this.openPopoAttachmentDialog = true
    } else {
      this.selectedDecoratedPopoAttach = undefined
      this.openPopoAttachmentDialog = false
    }
    this.popoAttachmentDialogLoading = false
  }

  @action changeOpenPopoAttachSuccessDialog(value) {
    this.openPopoAttachSuccessDialog = value
  }

  @action changeOpenPopoDetachSuccessDialog(value) {
    this.openPopoDetachSuccessDialog = value
  }

  //need updated wearableNftOwned first
  @action fetchAttachableNftCollection(selectedDecoratePopo: DecoratedPopoModel) {
    this.fetchAttachableNftCollectionLoading = true
    try {
      if (!selectedDecoratePopo) return
      let attachedNftIds = [] as any
      this.decoratedPoPos?.map((item) => {
        if (item.id === selectedDecoratePopo?.id) return item
        attachedNftIds = [...attachedNftIds, ...this.getNftIds(item)]
      })
      console.log('** fetchAttachableNftCollection ==> attachedNftIds', attachedNftIds)
      const target = [...this.wearableNftOwned].filter((nft) => nft?.id && !attachedNftIds.includes(nft.id))
      const attachNftIds = this.getNftIds(selectedDecoratePopo)

      this.attachableNftCollection = target.map((nft) => {
        return attachNftIds.includes(nft.id) ? { ...nft, forceAttach: true } : nft
      })
    } finally {
      this.fetchAttachableNftCollectionLoading = false
    }
  }

  @action getNftIds(obj: DecoratedPopoModel) {
    return [obj?.top?.id, obj?.bottom?.id, obj?.prop_accessory?.id, obj?.hair_accessory?.id, obj?.popo_nft?.id].filter(
      (item) => item != undefined
    )
  }

  @action changeAttachNft(isCheck, nft) {
    const clone = [...this.attachNfts]
    const index = clone.findIndex((item) => item.type === nft.type)
    if (isCheck) {
      if (index !== -1) clone.splice(index, 1, nft)
      else clone.push(nft)
    } else {
      if (index !== -1) clone.splice(index, 1)
    }
    this.attachNfts = clone
  }

  @asyncAction *handleActtachNfts() {
    try {
      this.handleActtachNftsLoading = true
      const payload = {
        decoratedPoPoId: this.selectedDecoratedPopoAttach.id,
        wearableNFTIds: map(
          this.attachNfts.filter((item) => !item.forceAttach),
          '_id'
        ),
      }
      yield apiService.decoratedPopos.attachNft(payload)
      this.changeOpenPopoAttachmentDialog(false)
      this.fetchDecoratedPopos()
      this.changeOpenPopoAttachSuccessDialog(true)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.handleActtachNftsLoading = false
    }
  }

  // ======> ATTACHMENT ENDED

  @action changeOpenConfirmMintingDialog(value) {
    this.openConfirmMintingDialog = value
  }

  @action changeOpenClaimPopoDialog(value) {
    this.openClaimPopoDialog = value
  }

  @asyncAction *changeOpenPopoDecorateDialog(value) {
    try {
      if (value && !this.isFetchingGalleries) {
        this.isFetchingGalleries = true
        yield when(() => this.galleries.length > 0)
        const clone = { ...this.groupedGalleries }
        this.decoratedNfts = [clone['body'][0]]
      }
      this.openPopoDecorateDialog = value
    } catch {
      //
    } finally {
      this.isFetchingGalleries = false
    }
  }

  @action changeOpenMintPopoSuccessDialog(value) {
    this.openMintPopoSuccessDialog = value
  }

  @asyncAction *fetchClosetAvatar() {
    try {
      const closetAvatars = yield apiService.popoNfts.fetchClosetAvatar()
      this.closetAvatars = orderBy(
        uniqBy(closetAvatars, (x) => x.id),
        ['updatedAt'],
        ['desc']
      )
      this.selectedClosetAvatar = this.closetAvatars[0]
    } catch (e) {
      //
    }
  }

  @action changeselectedClosetAvatar(avatarIndex) {
    this.selectedClosetAvatar = this.closetAvatars[avatarIndex]
  }

  @action changeDecoratedNfts(input) {
    const clone = [...this.decoratedNfts]
    const index = clone.findIndex((item) => item.type === input.type)
    if (index === -1 && input) {
      clone.push(input)
      this.decoratedNfts = clone
      return
    }
    if (this.decoratedNfts[index].id === input.id) {
      if (input.type !== 'body') {
        clone.splice(index, 1)
        this.decoratedNfts = clone
      }
    } else {
      clone.splice(index, 1, input)
      this.decoratedNfts = clone
    }
  }

  @action changeOpenUpdateAvatarSuccessDialog(value) {
    this.openUpdateAvatarSuccessDialog = value
  }

  @asyncAction *generatePopoAvatar(galleries: NftGalleryModel[]) {
    if (this.generatePopoAvatarLoading) return
    try {
      this.generatePopoAvatarLoading = true
      const popo = {}
      galleries.map((item: any) => {
        popo[item.type] = item.id
        return
      })
      const updatedProfile = yield apiService.popoNfts.generatePopoAvatar(popo)
      this.updatedPopoAvatar = updatedProfile.avatar
      this.changeOpenUpdateAvatarSuccessDialog(true)

      this.fetchClosetAvatar()
      walletStore.checkUserWallet()
    } catch (e) {
      console.log('error', e)
    } finally {
      this.generatePopoAvatarLoading = false
    }
  }

  @asyncAction *sendOptInPoPoSellerApplication() {
    try {
      console.log('======HERE', this.popoSellerHandler)
      this.optInLoading = true
      //Opt-in seller application
      yield this.popoSellerHandler?.sendOptInApplication(walletStore.account)
      this.isOptInSellerApplication = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.optInLoading = false
    }
  }

  @asyncAction *mintNFTs() {
    try {
      this.minting = true
      const nftAmount = this.selectedWearableNfts.length
      yield this.popoSellerHandler?.buy(walletStore.account, nftAmount + 1)
      const pendingNFTs = yield this.popoSellerHandler?.getPendingNFTs(walletStore.account)
      if (pendingNFTs.length) this.havePendingNFTs = true
      else return
      const popo = {}
      this.decoratedNfts.map((item: any) => {
        popo[item.type] = item.id
        return item
      })
      const claimedPopo = yield apiService.decoratedPopos.mintPoPo({ ...popo, assetIds: pendingNFTs })
      this.pendingDecoratedPoPo = claimedPopo
      this.builtPendingDecoratedPoPoInfo = this.buildDecoratedPopoInfo(claimedPopo)
      this.openConfirmMintingDialog = false
      this.changeOpenClaimPopoDialog(true)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.minting = false
    }
  }

  @action changeOpenRemindClaimPopoDialog(value) {
    this.openRemindClaimPopoDialog = value
  }

  @asyncAction *claimPopo() {
    if (this.claimPopoLoading) return
    try {
      this.claimPopoLoading = true
      const popo = {}
      yield this.popoSellerHandler?.claim(walletStore.account)
      this.decoratedNfts.map((item: any) => {
        popo[item.type] = item.id
        return item
      })
      const claimedPopo = yield apiService.decoratedPopos.claimPopo({ decoratedPoPoId: this.pendingDecoratedPoPo.id })
      this.claimedNftInfo = this.buildDecoratedPopoInfo(claimedPopo)
      this.havePendingNFTs = false
      this.pendingDecoratedPoPo = undefined
      this.openConfirmMintingDialog = false
      this.changeOpenPopoDecorateDialog(false)
      this.changeOpenClaimPopoDialog(false)
      this.fetchDecoratedPopos()
      this.changeOpenMintPopoSuccessDialog(true)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.claimPopoLoading = false
    }
  }

  @asyncAction *fetchGalleries() {
    this.galleries = yield apiService.popoNfts.fetchGalleries({})
  }

  @asyncAction *fetchDecoratedPopos() {
    try {
      if (this.fetchDecoratedPoposLoading) return
      this.fetchDecoratedPoposLoading = true
      console.log('fetchDecoratedPopos')
      this.decoratedPoPos = yield apiService.decoratedPopos.fetchDecoratedPopos()
      if (!this.decoratedPoPos?.length) return
      this.updateActiveNftInfo()
      this.builtDecoratedPopos = []
      this.decoratedPoPos.forEach((item) => {
        const allNftInfos = this.buildGeneralNftInfo(item)
        this.builtDecoratedPopos.push({
          ...item,
          decorators: allNftInfos,
          eLevel: item?.popo_nft?.eLevel,
          aLevel: item?.popo_nft?.aLevel,
          rLevel: item?.popo_nft?.rLevel,
          nLevel: item?.popo_nft?.nLevel,
          assetId: item?.popo_nft?.assetId,
        })
      })
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.loadedDecoratedPopos = true
      this.fetchDecoratedPoposLoading = false
      // console.log('this.loadedDecoratedPopos', this.loadedDecoratedPopos)
    }
  }

  @asyncAction *updateAvatar() {
    if (!this.selectedClosetAvatar) return
    try {
      this.updateAvatarLoading = true
      const updatedProfile = yield apiService.profiles.updateAvatar({ popoAvatarId: this.selectedClosetAvatar?.id })
      this.updatedPopoAvatar = updatedProfile.avatar
      this.changeOpenUpdateAvatarSuccessDialog(true)
      this.fetchClosetAvatar()
      walletStore.checkUserWallet()
    } catch (e: any) {
      snackController.error(e.message || e.msg)
    } finally {
      this.updateAvatarLoading = false
    }
  }

  @asyncAction *setAvatarFromDecoratedPopo(decoratedPopo) {
    if (this.generatePopoAvatarLoading) return
    const galleries = [...(decoratedPopo.wearableGalleries || []), ...(decoratedPopo.popoGalleries || [])]
    yield this.generatePopoAvatar(galleries)
  }

  @asyncAction *setActiveDecoratedPoPo(decoratedPoPoId: string) {
    try {
      loadingController.increaseRequest()
      this.setActiveDecoratedPoPoLoading = true
      yield apiService.profiles.setActiveDecoratedPoPo({ decoratedPoPoId })
      snackController.success('Set active NFT successfully')
      this.activeDecoratedPopoNftId = decoratedPoPoId
      this.updateActiveNftInfo()
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.setActiveDecoratedPoPoLoading = false
      loadingController.decreaseRequest()
    }
  }

  @action updateActiveNftInfo() {
    this.decoratedPoPos.forEach((item) => {
      if (item.id === this.activeDecoratedPopoNftId) this.activeDecoratedPopoNftInfo = this.buildDecoratedPopoInfo(item)
    })
  }

  @action buildDecoratedPopoInfo(decoratedPoPo: DecoratedPopoModel) {
    const popoGalleries = [] as any
    const wearableGalleries = [] as any
    const data = decoratedPoPo.data
    if (decoratedPoPo?.popo_nft) {
      if (data?.body) popoGalleries.push(data.body)
      if (data?.shoe) popoGalleries.push(data.shoe)
      if (data?.face_accessory) popoGalleries.push(data.face_accessory)
      if (data?.background_accessory) popoGalleries.push(data.background_accessory)
    }
    if (decoratedPoPo?.top)
      wearableGalleries.push({ ...data?.top, assetId: decoratedPoPo?.top?.assetId, nftId: decoratedPoPo?.top?.id })
    if (decoratedPoPo?.bottom)
      wearableGalleries.push({
        ...data?.bottom,
        assetId: decoratedPoPo?.bottom?.assetId,
        nftId: decoratedPoPo?.bottom?.id,
      })
    if (decoratedPoPo?.hair_accessory)
      wearableGalleries.push({
        ...data?.hair_accessory,
        assetId: decoratedPoPo?.hair_accessory?.assetId,
        nftId: decoratedPoPo?.hair_accessory?.id,
      })
    if (decoratedPoPo?.prop_accessory)
      wearableGalleries.push({
        ...data?.prop_accessory,
        assetId: decoratedPoPo?.prop_accessory?.assetId,
        nftId: decoratedPoPo?.prop_accessory?.id,
      })

    return {
      ...decoratedPoPo,
      popoGalleries,
      wearableGalleries,
    }
  }

  @action buildPopoNftInfo(decoratedPoPo: DecoratedPopoModel) {
    const popoNftInfos = [] as any
    const data = decoratedPoPo.data
    if (decoratedPoPo?.popo_nft) {
      if (data?.body) popoNftInfos.push(data.body)
      if (data?.shoe) popoNftInfos.push(data.shoe)
      if (data?.face_accessory) popoNftInfos.push(data.face_accessory)
      if (data?.background_accessory) popoNftInfos.push(data.background_accessory)
    }

    return popoNftInfos
  }

  @action buildGeneralNftInfo(decoratedPoPo: DecoratedPopoModel) {
    const allNftInfos = [] as any
    const data = decoratedPoPo.data
    if (data?.body) allNftInfos.push(data.body)
    if (data?.shoe) allNftInfos.push(data.shoe)
    if (data?.face_accessory) allNftInfos.push(data.face_accessory)
    if (data?.background_accessory) allNftInfos.push(data.background_accessory)

    if (decoratedPoPo?.top)
      allNftInfos.push({ ...data?.top, assetId: decoratedPoPo?.top?.assetId, nftId: decoratedPoPo?.top?.id })
    if (decoratedPoPo?.bottom)
      allNftInfos.push({ ...data?.bottom, assetId: decoratedPoPo?.bottom?.assetId, nftId: decoratedPoPo?.bottom?.id })
    if (decoratedPoPo?.hair_accessory)
      allNftInfos.push({
        ...data?.hair_accessory,
        assetId: decoratedPoPo?.hair_accessory?.assetId,
        nftId: decoratedPoPo?.hair_accessory?.id,
      })
    if (decoratedPoPo?.prop_accessory)
      allNftInfos.push({
        ...data?.prop_accessory,
        assetId: decoratedPoPo?.prop_accessory?.assetId,
        nftId: decoratedPoPo?.prop_accessory?.id,
      })

    return allNftInfos
  }

  @action continueDecorate(value) {
    this.changeOpenMintPopoSuccessDialog(false)
    this.changeOpenPopoDecorateDialog(value)
  }

  @action calculateBoost(sum) {
    switch (sum) {
      case 0:
      case 1:
        return {
          index: 0.2,
          cost: 15,
        }
      case 2:
        return {
          index: 0.3,
          cost: 25,
        }
      case 3:
        return {
          index: 0.4,
          cost: 35,
        }
      case 4:
        return {
          index: 0.5,
          cost: 45,
        }
      default:
        return {
          index: 0,
          cost: 0,
        }
    }
  }

  @computed get groupedAttachableNft() {
    return mapValues(groupBy(this.attachableNftCollection, 'type'), (clist) => clist.map((nft) => omit(nft, ''))) || {}
  }
  @computed get isEmptyAttachableNft() {
    return isEmpty(this.groupedAttachableNft)
  }

  @computed get groupedGalleries() {
    return mapValues(groupBy(this.galleries, 'type'), (clist) => clist.map((gallery) => omit(gallery, ''))) || []
  }
  @computed get displayAttachNfts() {
    return [...(this.selectedDecoratedPopoAttach?.popoNftInfos || []), ...this.attachNfts] || []
  }

  @computed get selectedWearableNfts() {
    return this.decoratedNfts.filter((item) => WEARABLE_NFT_TYPES.includes(item.type as any))
  }

  @computed get selectedPopoNfts() {
    return this.decoratedNfts.filter((item) => !WEARABLE_NFT_TYPES.includes(item.type as any))
  }

  @computed get newAttachNfts() {
    return this.attachNfts.filter((item) => !item.forceAttach)
  }

  @computed get concatedWearableNftName() {
    return map(this.selectedWearableNfts, 'type').join(' ')
  }

  @computed get activePopoNft() {
    return this.activeDecoratedPopoNftInfo?.popo_nft
  }

  @computed get activeNfts() {
    return this.activeDecoratedPopoNftInfo
      ? [...this.activeDecoratedPopoNftInfo.popoGalleries, ...this.activeDecoratedPopoNftInfo.wearableGalleries]
      : []
  }

  @computed get detachNfts() {
    return this.selectedDecoratedPopoDetach?.wearableGalleries || []
  }

  @computed get decoratingBoost() {
    const wearableTypes = ['top', 'bottom', 'hair_accessory', 'prop_accessory']
    const length = this.decoratedNfts.filter((item: any) => wearableTypes.includes(item.type)).length
    return this.calculateBoost(length)
  }

  @computed get pendingPopoNft() {
    return this.builtPendingDecoratedPoPoInfo?.popoGalleries
  }

  @computed get pendingPopoBody() {
    return this.pendingPopoNft?.find((item) => item.type === 'body')
  }

  @computed get pendingWearableNfts() {
    return this.builtPendingDecoratedPoPoInfo?.wearableGalleries
  }
}

export const popoStore = new PopoStore()
